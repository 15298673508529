export const environment = {
  general: {
    production: false,
    product: 'retail',
    title: 'Tienda Buho',
    url: 'https://server.buhomanager.com:444/',
    useMultiClient: false,
    nameMultiClient: 'qmucho',
    logoUrl: 'https://buho-images.s3.sa-east-1.amazonaws.com/qmucho/logo4.png',
    carouselImages: 2,
    phoneNumber: 3541585513,
    columnsItemMobile: 2
  },
  bucketConfig: {
    region: 'sa-east-1',
    accessKey: '',
    secretAccessKey: '',
    bucketName: 'buho-images',
    bucketFolderToSavePhotos: 'multiclient/upload/photos-articles/',
    bucketFolderToSaveFiles: 'multiclient/upload/files/',
  },
  htmlContent: {
    separator: '<div class="banner-separator"><h3>Consultas</h3><span>Realiza tus consultas por whatsapp</span></div>'
  },
  mercadoPago: {
    useMercadoPago: true,
    MercadoPago_Token_Test: 'TEST-5706666289495318-031910-e51e7aed7581370c6be8cf2bba536b7c-67268897',
    MercadoPago_Token_Prod: 'APP_USR-5706666289495318-031910-24676bbdf9a590d2b343115ddc9d71dd-67268897',
    MercadoPago_Back_Url: 'https://quierememuchokids.com'
  }
};
